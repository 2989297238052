
                        @import "@/assets/scss/_variables.scss";
                        @import "@/assets/scss/_mixins.scss";
                    

.remove-icon {
   color: red;
   font-size: 0.5rem;
   font-weight: bold;
   cursor: pointer;
}
