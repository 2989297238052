.z0 {z-index: 0 !important;}
.flex100 { flex: 0 0 100% !important; max-width: 100% !important; }
.oh{ overflow: hidden }
.mlr0{ margin-left: 0px; margin-right: 0px}
.mt100 { margin-top: 100px !important; }
.mt80 { margin-top: 80px !important; }
.mt60 { margin-top: 60px !important; }
.mt50 { margin-top: 50px !important; }
.mt45 { margin-top: 45px !important; }
.mt40 { margin-top: 40px !important; }
.mt35 { margin-top: 35px !important; }
.mt30 { margin-top: 30px !important; }
.mt25 { margin-top: 25px !important; }
.mt20 { margin-top: 20px !important; }
.mt15 { margin-top: 15px !important; }
.mt10 { margin-top: 10px !important; }
.mt5 { margin-top: 5px !important; }
.mt0 { margin-top: 0px !important; }
.mt-50 { margin-top: -50px !important; }
.mt-45 { margin-top: -45px !important; }
.mt-40 { margin-top: -40px !important; }
.mt-35 { margin-top: -35px !important; }
.mt-30 { margin-top: -30px !important; }
.mt-25 { margin-top: -25px !important; }
.mt-20 { margin-top: -20px !important; }
.mt-15 { margin-top: -15px !important; }
.mt-10 { margin-top: -10px !important; }
.mt-5 { margin-top: -5px !important; }
.mr100 { margin-right: 100px !important; }
.mr80 { margin-right: 80px !important; }
.mr60 { margin-right: 60px !important; }
.mr50 { margin-right: 50px !important; }
.mr45 { margin-right: 45px !important; }
.mr40 { margin-right: 40px !important; }
.mr35 { margin-right: 35px !important; }
.mr30 { margin-right: 30px !important; }
.mr25 { margin-right: 25px !important; }
.mr20 { margin-right: 20px !important; }
.mr15 { margin-right: 15px !important; }
.mr10 { margin-right: 10px !important; }
.mr5 { margin-right: 5px !important; }
.mr0 { margin-right: 0px !important; }
.mr-50 { margin-right: -50px !important; }
.mr-45 { margin-right: -45px !important; }
.mr-40 { margin-right: -40px !important; }
.mr-35 { margin-right: -35px !important; }
.mr-30 { margin-right: -30px !important; }
.mr-25 { margin-right: -25px !important; }
.mr-20 { margin-right: -20px !important; }
.mr-15 { margin-right: -15px !important; }
.mr-10 { margin-right: -10px !important; }
.mr-5 { margin-right: -5px !important; }
.mb100 { margin-bottom: 100px !important; }
.mb90 { margin-bottom: 90px !important; }
.mb80 { margin-bottom: 80px !important; }
.mb70 { margin-bottom: 70px; }
.mb60 { margin-bottom: 60px !important; }
.mb50 { margin-bottom: 50px !important; }
.mb45 { margin-bottom: 45px !important; }
.mb40 { margin-bottom: 40px !important; }
.mb35 { margin-bottom: 35px !important; }
.mb30 { margin-bottom: 30px !important; }
.mb25 { margin-bottom: 25px !important; }
.mb20 { margin-bottom: 20px !important; }
.mb15 { margin-bottom: 15px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb16 { margin-bottom: 16px !important; }
.mb5 { margin-bottom: 5px !important; }
.mb0 { margin-bottom: 0px !important; }
.mb-50 { margin-bottom: -50px !important; }
.mb-45 { margin-bottom: -45px !important; }
.mb-40 { margin-bottom: -40px !important; }
.mb-35 { margin-bottom: -35px !important; }
.mb-30 { margin-bottom: -30px !important; }
.mb-25 { margin-bottom: -25px !important; }
.mb-20 { margin-bottom: -20px !important; }
.mb-15 { margin-bottom: -15px !important; }
.mb-10 { margin-bottom: -10px !important; }
.mb-5 { margin-bottom: -5px !important; }
.ml100 { margin-left: 100px !important; }
.ml80 { margin-left: 80px !important; }
.ml60 { margin-left: 60px !important; }
.ml50 { margin-left: 50px !important; }
.ml45 { margin-left: 45px !important; }
.ml40 { margin-left: 40px !important; }
.ml35 { margin-left: 35px !important; }
.ml30 { margin-left: 30px !important; }
.ml25 { margin-left: 25px !important; }
.ml20 { margin-left: 20px !important; }
.ml15 { margin-left: 15px !important; }
.ml10 { margin-left: 10px !important; }
.ml5 { margin-left: 5px !important; }
.ml0 { margin-left: 0px !important; }
.ml-50 { margin-left: -50px !important; }
.ml-45 { margin-left: -45px !important; }
.ml-40 { margin-left: -40px !important; }
.ml-35 { margin-left: -35px !important; }
.ml-30 { margin-left: -30px !important; }
.ml-25 { margin-left: -25px !important; }
.ml-20 { margin-left: -20px !important; }
.ml-15 { margin-left: -15px !important; }
.ml-10 { margin-left: -10px !important; }
.ml-5 { margin-left: -5px !important; }
.pt100 { padding-top: 100px !important; }
.pt80 { padding-top: 80px !important; }
.pt60 { padding-top: 60px !important; }
.pt50 { padding-top: 50px !important; }
.pt45 { padding-top: 45px !important; }
.pt40 { padding-top: 40px !important; }
.pt35 { padding-top: 35px !important; }
.pt30 { padding-top: 30px !important; }
.pt25 { padding-top: 25px !important; }
.pt20 { padding-top: 20px !important; }
.pt15 { padding-top: 15px !important; }
.pt10 { padding-top: 10px !important; }
.pt5 { padding-top: 5px !important; }
.pt0 { padding-top: 0px !important; }
.pr110 { padding-right: 110px !important; }
.pr100 { padding-right: 100px !important; }
.pr80 { padding-right: 80px !important; }
.pr60 { padding-right: 60px !important; }
.pr50 { padding-right: 50px !important; }
.pr45 { padding-right: 45px !important; }
.pr40 { padding-right: 40px !important; }
.pr35 { padding-right: 35px !important; }
.pr30 { padding-right: 30px !important; }
.pr25 { padding-right: 25px !important; }
.pr20 { padding-right: 20px !important; }
.pr15 { padding-right: 15px !important; }
.pr10 { padding-right: 10px !important; }
.pr5 { padding-right: 5px !important; }
.pr0 { padding-right: 0px !important; }
.pb100 { padding-bottom: 100px !important; }
.pb80 { padding-bottom: 80px !important; }
.pb70 { padding-bottom: 70px !important; }
.pb60 { padding-bottom: 60px !important; }
.pb50 { padding-bottom: 50px !important; }
.pb45 { padding-bottom: 45px !important; }
.pb40 { padding-bottom: 40px !important; }
.pb35 { padding-bottom: 35px !important; }
.pb30 { padding-bottom: 30px !important; }
.pb25 { padding-bottom: 25px !important; }
.pb20 { padding-bottom: 20px !important; }
.pb15 { padding-bottom: 15px !important; }
.pb10 { padding-bottom: 10px !important; }
.pb5 { padding-bottom: 5px !important; }
.pb0 { padding-bottom: 0px !important; }
.pl110 { padding-left: 110px !important; }
.pl100 { padding-left: 100px !important; }
.pl80 { padding-left: 80px !important; }
.pl60 { padding-left: 60px !important; }
.pl50 { padding-left: 50px !important; }
.pl45 { padding-left: 45px !important; }
.pl40 { padding-left: 40px !important; }
.pl35 { padding-left: 35px !important; }
.pl30 { padding-left: 30px !important; }
.pl25 { padding-left: 25px !important; }
.pl20 { padding-left: 20px !important; }
.pl15 { padding-left: 15px !important; }
.pl10 { padding-left: 10px !important; }
.pl5 { padding-left: 5px !important; }
.pl0 { padding-left: 0px !important; }
.pd0 { padding: 0px !important; }
.mg0 { margin: 0px !important; }
.prelative { position: relative; }
// Function for converting a px based font-size to rem.
@function calculateRem($size) {
    $remSize: $size / $base-font-size;
    @return #{$remSize}rem;
}
@mixin fontSize($size) {
    font-size: calculateRem($size);
}

@mixin lineHeight($size) {
    line-height: calculateRem($size);
}
@mixin transition($sec, $prop: all) {
    -ms-transition: $prop #{$sec}s cubic-bezier(0.4, 0, 0.2, 1);
    transition: $prop #{$sec}s cubic-bezier(0.4, 0, 0.2, 1);
}
@mixin svgColor($color) {
    & *[fill="#fff"],
    & *[fill="#ffffff"] {
        fill: $color;
        @include transition(0.3)
    }
    & *[stroke="#fff"],
    & *[stroke="#ffffff"] {
        stroke: $color;
        @include transition(0.3)
    }
}

.animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}
@-webkit-keyframes shakeX {
    0%,
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }
}

@keyframes shakeX {
    0%,
    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0)
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0)
    }
}

.animate__shakeX {
    -webkit-animation-name: shakeX;
    animation-name: shakeX
}

/**  LEFT ANIMATION  **/
.animation-left-enter-active {
    animation: left-coming 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 2;
}

.animation-left-leave-active {
    animation: left-going 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1;
    position: absolute !important;
    top: 0;
    width: 100%;
}

@keyframes left-going {
    from {
        left: 0%;
        opacity: 1;
    }
    to {
        left: -100%;
        opacity: 0;
    }
}

@keyframes left-coming {
    from {
        left: 100%;
        opacity: 0;
    }
    to {
        left: 0%;
        opacity: 1;
    }
}

/**  RIGHT ANIMATION  **/
.animation-right-enter-active {
    animation: right-coming 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 2;
}

.animation-right-leave-active {
    animation: right-going 0.8s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1;
    position: absolute !important;
    top: 0;
    width: 100%;
}

@keyframes right-going {
    from {
        left: 0%;
        opacity: 1;
    }
    to {
        left: 100%;
        opacity: 0;
    }
}

@keyframes right-coming {
    from {
        left: -100%;
        opacity: 0;
    }
    to {
        left: 0%;
        opacity: 1;
    }
}

@keyframes pulse {
   0% {
      transform: scale(0.55);
   }

   100% {
      transform: scale(1);
   }
}


@mixin bgImageCover {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
